import style from "./contact.module.css"
import NavBar from "../../components/navbar/navbar";
import SDWT from "../../structures/splitDivWithTitle/sdwt";
import Button from "../../structures/button/button";
import "./alerts.css"
import Swal from 'sweetalert2'
import React, { useState, useEffect, useRef } from "react";
import emailjs from '@emailjs/browser';
import icon1 from "../../static/contacto/contactus.svg";

const Contact = () => {
    // Vars
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [msg, setMsg] = useState("");
    const [errMsg, setError] = useState("Ingrese un Nombre");
    const [nameLen, setNameLen] = useState(0);
    const [lastNameLen, setLastNameLen] = useState(0);
    const [emailLen, setEmailLen] = useState(0);

    // UX
    const nameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const msgRef = useRef();
    const contactForm = useRef();

    // Functions
    function is_Error(errMsg) {
        return errMsg !== "";
    }
    function formReset() {
        setName("");
        setLastName("");
        setEmail("");
        setMsg("")
    }

    function detectAutofill(len, previousLen) {
        return (len - previousLen) > 2;
    }

    // Logic
    useEffect(() => {
        nameRef.current.focus();
    }, []);

    useEffect( () => {
        // Detect Autofill
        let len = name.length;
        if (detectAutofill(len, nameLen)) {
            lastNameRef.current.focus();
        }

        len = lastName.length;
        if (detectAutofill(len, lastNameLen)) {
            emailRef.current.focus();
        }

        len = email.length;
        if (detectAutofill(len, emailLen)) {
            msgRef.current.focus();
        }

        // Clear Error
        if (is_Error()) {
            setError("");
        }

        // Update Lenghts
        setNameLen(name.length);
        setLastNameLen(lastName.length);
        setEmailLen(email.length);

    },[name, lastName, email, msg, emailLen, lastNameLen, nameLen])

    // Pop-ups
    function swalSuccess() {
        Swal.fire({
            title: "Correo Enviado",
            text: "¡Nos pondremos en contacto a la brevedad!",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            showCloseButton: true
        })
    }

    function swalError() {
        Swal.fire({
            title: "¡Ah ocurrido un error!",
            text: "Intenta contactarnos al correo ventas@metricpro.cl",
            icon: "error",
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true,
            showCloseButton: true
          })
    }

    // Submit Function
    const handleContact = async(e) => {
        e.preventDefault();
        try {
            if (name === "") {
                setError("Debe proporcionar un nombre.")
                nameRef.current.focus();
            }

            else if (lastName === "") {
                setError("Debe proporcionar un apellido.")
                lastNameRef.current.focus();
            }

            else if (email === "") {
                setError("Debe proporcionar un correo electrónico.");  
                emailRef.current.focus();  
            }
            
            else if (!email.includes("@") || !email.includes(".")) {
                setError('Correo inválido ej: "ejemplo@dominio.com".');
                emailRef.current.focus();
            }

            else if (msg === "") {
                setError("Debe escribir algún mensaje.")
                msgRef.current.focus();
            }

            else {
                    emailjs.sendForm("service_qucvqk5", "template_h6avbkn", e.target, "WYheXAZGL9YkyWFCW")
                    .then((result) => {
                        console.log(`request status: ${result}`)
                        swalSuccess();
                        formReset()
                    }, (error) => {
                        console.log(`request status: ${error}`)
                        swalError();
                        formReset()
                    });
            }

        } catch {

        }
    }

    return(
        <React.Fragment>
            <header>
                <NavBar />
            </header>
            <section className={style.contentSection}>
                <div className={style.contact}>
                    <SDWT>
                        <div className={style.Icon}>
                            <img src={icon1} alt="Ilustración de contacto" />
                        </div>
                        <div className={style.formDiv}>
                            <form onSubmit={handleContact} className={style.form} ref={contactForm} noValidate type="submit">
                                {/* Nombre */}
                                <label htmlFor="name" className={errMsg ? style.labelError : style.label}>NOMBRE</label>
                                <input type="text" name="name"
                                    placeholder="Indicanos tu nombre..." 
                                    ref={nameRef} value={name} onChange={(e) => {setName(e.target.value)}} required 
                                    className={errMsg ? style.inputError : style.input}
                                />

                                {/* Apellido */}
                                <label htmlFor="lastName" className={errMsg ? style.labelError : style.label}>APELLIDO</label>
                                <input type="text" name="lastName"
                                    placeholder="Indicanos tu apellido..." 
                                    ref={lastNameRef} value={lastName} onChange={(e) => {setLastName(e.target.value)}} required 
                                    className={errMsg ? style.inputError : style.input}
                                />

                                {/* Email */}
                                <label htmlFor="email" className={errMsg ? style.labelError : style.label}>CORREO ELECTRONICO</label>
                                <input type="email" name="email"
                                    placeholder="Ingresa tu correo electrónico..." 
                                    ref={emailRef} value={email} onChange={(e) => {setEmail(e.target.value)}} required 
                                    className={errMsg ? style.inputError : style.input}
                                />

                                {/* Message */}
                                <label htmlFor="msg" className={errMsg ? style.labelError : style.label}>MENSAJE</label>
                                <textarea  type="textarea" name="msg"
                                    placeholder="Ingresa tu mensaje..." rows="4"
                                    ref={msgRef} value={msg} onChange={(e) => {setMsg(e.target.value)}} required 
                                    className={errMsg ? style.inputError : style.input}
                                />

                                <label htmlFor="offscreen" className={style.offscreen}>I</label>

                                <Button className={errMsg ? style.submitError : style.submit}>
                                    ENVIAR
                                </Button>

                                <div className={style.errMsg}><p className={errMsg ? style.errMsgString : style.offscreen} aria-live="assertive">{errMsg}</p></div>

                            </form>
                        </div>
                    </SDWT>
                </div>
            </section>
        </React.Fragment>
    );
}

export default Contact;