import React from "react";
import style from "./card.module.css"

const Card= (props) => {
    return (
        <div className={props.className? `${style.Card} ${props.className}`:style.Card}>
            <div className={style.Icon}>
                <img src={props.src} alt="Icon"></img>
            </div>
            <div className={style.contentWrapper}>
                <div className={props.titleClassName? `${style.Title} ${props.titleClassName}`:style.Title} >
                    <img src={props.src} alt="Icon"></img>{props.Title}
                </div>
                <div className={props.contentClassName ? `${style.Content} ${props.contentClassName}`: style.Content}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}
 
export default Card;  