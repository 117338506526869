import style from "./fsdiv.module.css"

const FsDiv = (props) => {
    return(
        <div className={props.className? `${style.fullScreenDiv} ${props.className}` : `${style.fullScreenDiv}`}>
            {props.children}
        </div>
    );
};

export default FsDiv;