import NavBar from "../../components/navbar/navbar";
import Main from "../../structures/MainDiv/main";
import FsDiv from "../../structures/FullScreenDiv/fsdiv"
import SDWT from "../../structures/splitDivWithTitle/sdwt";
import {useState} from "react";
import style from "./faqs.module.css"
import icon1 from "../../static/faqs/icon_1.svg"
import arrow from "../../static/faqs/arrow.png"

const FAQs = () => {
    // Vars
    const [activeId, setActiveId] = useState(0);

    // Functions
    const handleClick = async(e) => {
        let key = 0;
        try {
            key = Number(e.target.id.substring(0, 2));
            if (Number.isNaN(key)) {
                key = Number(e.target.id.substring(0, 1));
            }
        } catch (error) {
            key = Number(e.target.id.substring(0, 1));
        }
        console.log(key);
        key = key + 1
        if((key !== activeId) && (activeId !== null)) {
            try {
                document.getElementById(`${activeId}`).setAttribute("class", style.answer);
                document.getElementById(`${activeId-1}arrow`).setAttribute("class", style.arrowHide);
            } catch {}
            document.getElementById(`${key}`).setAttribute("class", style.show);
            document.getElementById(`${key-1}arrow`).setAttribute("class", style.arrowShow);
            setActiveId(key);
        }
        else if(key === activeId) {
            document.getElementById(`${key}`).setAttribute("class", style.answer);
            document.getElementById(`${key-1}arrow`).setAttribute("class", style.arrowHide);
            setActiveId(0);
        }
    }

    return (
        <FsDiv>
            <header>
                <NavBar />
            </header>
            <Main>
                <main className={style.mainDiv}>
                    <SDWT mainClassName={style.contentContainer} className={style.contentContainer}>
                        <div className={style.FAQs}>
                            <h1 className={style.pageTitle}>PREGUTAS <span>FRECUENTES</span></h1>
                            <div className={style.element}>
                                <div className={style.question} onClick={handleClick} id="1">
                                    <h3 onClick={handleClick} id="1title">¿Cuál es la diferencia entre las compuertas remotas/automatizadas con las motorizadas?</h3>
                                    <img src={arrow} alt=">" onClick={handleClick} id="1arrow"></img>
                                </div>
                                <div className={style.answer} id="2">
                                    <p>La diferencia entre estas soluciones es que las compuertas remotas o automatizadas cuentan con una RTCU (Remote Telemetry and Control Unit), que es un componente que tal como su nombre indica es una unidad de control y telemetría remota. Esto es muy similar a un microcontrolador, que nos permite leer y enviar datos digitales o análogos. De esta forma podremos conocer la posición de la compuerta, como también darle la instrucción de moverla. Mientras que, en las motorizadas, al no poseer este componente, no se podrá conocer ni dar una instrucción remota para su movimiento, sino que tiene que ser de forma física a través de la botonera.</p>
                                    <p>Por otra parte, la RTCU también nos permitirá monitorear el estado de distintos componentes de la instalación, como podría ser la batería, por lo que podremos anticiparnos a cortes de suministro de electricidad, entre otros parámetros más específicos que nos indicarán si la compuerta se encuentra operativa o no.</p>
                                </div>
                            </div>

                            <div className={style.element}>
                                <div className={style.question} onClick={handleClick} id="3">
                                    <h3 onClick={handleClick} id="3title">¿Qué uso tienen las telemetrías?</h3>
                                    <img src={arrow} alt=">" onClick={handleClick} id="3arrow"></img>
                                </div>
                                <div className={style.answer} id="4">
                                    <p>La telemetría es la medición a través de sensores distintas magnitudes físicas como podría ser la temperatura, distancia, entre otros. En nuestro rubro existe la necesidad de administrar recursos hídricos, para esto los sensores nos resultan de gran utilidad para medir estos datos en una frecuencia de tiempo especificada, sin embargo, la lectura de estos datos no tendrá ningún impacto en la administración si no es desplegada o almacenada para posteriormente ser visualizada y/o analizada por parte del asignador de recursos.</p>
                                    <p>Para solventar este componente faltante, nuestra empresa cuenta con una plataforma que almacena y que además ofrece herramientas de visualización para reducir el trabajo de datos para el administrador en búsqueda de agilizar la toma de decisiones.</p>
                                </div>
                            </div>

                            <div className={style.element}>
                                <div className={style.question} onClick={handleClick} id="5">
                                    <h3 onClick={handleClick} id="5title">¿Qué es la plataforma Metric Pro?</h3>
                                    <img src={arrow} alt=">" onClick={handleClick} id="5arrow"></img>
                                </div>
                                <div className={style.answer} id="6">
                                    <p>La plataforma Metric Pro es una herramienta (aplicación web) que nos permitirá conectarnos al servidor de nuestra empresa, que es donde se almacenan los distintos datos de nuestros clientes, al funcionar en la web les permitirá a nuestros usuarios trascender de una aplicación de escritorio para poder acceder a su cuenta en cualquier dispositivo inteligente con acceso a una conexión de internet.</p>
                                    <p>Esta herramienta también nos ofrece una organización de los recursos (compuertas y/o telemetrías), donde se puede acceder a cada uno para ver los detalles de los distintos productos instalados, cada uno de sus registros históricos y la visualización de estos.</p>
                                </div>
                            </div>

                            <div className={style.element}>
                                <div className={style.question} onClick={handleClick} id="7">
                                    <h3 onClick={handleClick} id="7title">¿Quién tiene acceso a los datos?</h3>
                                    <img src={arrow} alt=">" onClick={handleClick} id="7arrow"></img>
                                </div>
                                <div className={style.answer} id="8">
                                    <p>Los datos sólo serán asequibles por el usuario y los administradores del back-end, estos últimos con la finalidad de monitorear el estado de distintos componentes para anticiparnos a posibles desperfectos, como también para brindar asistencia remota a nuestros usuarios.</p>
                                    <p>Si bien se puede acceder a la plataforma desde cualquier dispositivo se debe contar con las credenciales del usuario, es decir, nombre de usuario y contraseña para ingresar, los datos de nuestros clientes no serán vendidos, publicados ni compartidos por nuestra empresa sin previa autorización del usuario.</p>
                                </div>
                            </div>

                            <div className={style.element}>
                                <div className={style.question} onClick={handleClick} id="9">
                                    <h3 onClick={handleClick} id="9title">¿Cómo escoger el sensor más adecuado para mi telemetría?</h3>
                                    <img src={arrow} alt=">" onClick={handleClick} id="9arrow"></img>
                                </div>
                                <div className={style.answer} id="10">
                                    <p>Si bien todos los sensores retornan el nivel de agua en un punto, la metodología es distinta, la toma de decisión de qué sensor usar tiene tres dimensiones que corresponden a la económica (precio del sensor), factores ambientales (agentes externos que podrían generar una interferencia en la medición) y el objetivo de precisión esperada por el proyecto.</p>
                                    <p>Por esto nuestra empresa ofrece un análisis en este sentido para guiar a nuestros clientes, por lo que si tienes un proyecto en mente no dudes en contactarnos para recibir asistencia técnica.</p>
                                </div>
                            </div>

                            <div className={style.element}>
                                <div className={style.question} onClick={handleClick} id="11">
                                    <h3 onClick={handleClick} id="11title">¿Qué requisitos se necesitan para isntalar una compuerta y/o telemetría?</h3>
                                    <img src={arrow} alt=">" onClick={handleClick} id="11arrow"></img>
                                </div>
                                <div className={style.answer} id="12">
                                    <p>Tanto las compuertas como las telemetrías se alimentan por energía solar, por lo que no es necesario contar con una conexión a la red eléctrica.</p>
                                    <p>En términos de obra civil se debe contar con una infraestructura que permita la instalación de los equipos, nuestra empresa puede habilitar o realizar modificaciones de obras civiles para poder llevar a cabo la instalación.</p>
                                    <p>En las telemetrías es requerido contar con una curva de descarga que nos permite calcular el caudal o volumen en función de la altura medida por el sensor, de todas formas, si no se cuenta con esta información ya sea por modificaciones en la obra civil o por cualquier otra razón, se podrá cotizar esta modelación.</p>
                                </div>
                            </div>

                            <div className={style.element}>
                                <div className={style.question} onClick={handleClick} id="13">
                                    <h3 onClick={handleClick} id="13title">¿Cómo es la modalidad de pago?</h3>
                                    <img src={arrow} alt=">" onClick={handleClick} id="13arrow"></img>
                                </div>
                                <div className={style.answer} id="14">
                                    <p>La modalidad de pago dependerá si la obra está financiada por la CNR o si es una contratación privada, donde se conversará la modalidad de pago según la magnitud de la obra.</p>
                                    <p>Además, nuestros clientes no deberán pagar por el uso de la plataforma, ya que el único pago posterior será el de las mantenciones de los equipos que será estipulado en el contrato según la cantidad de equipos instalados.</p>
                                </div>
                            </div>

                            <div className={style.element}>
                                <div className={style.question} onClick={handleClick} id="15">
                                    <h3 onClick={handleClick} id="15title">¿La infrastructura instalada tiene garantía?</h3>
                                    <img src={arrow} alt=">" onClick={handleClick} id="15arrow"></img>
                                </div>
                                <div className={style.answer} id="16">
                                    <p>Si, todos nuestros productos y sus componentes tienen garantía por 1 año. Una vez vencido este plazo, entrarán en vigencia las mantenciones, con las cuales se garantiza el correcto funcionamiento de los equipos.</p>
                                </div>
                            </div>

                        </div>
                        <div className={style.Icon}>
                            <img src={icon1} alt="Icon"></img>
                        </div>
                    </SDWT>
                </main>
            </Main>
        </FsDiv>
    );
}

export default FAQs;