import style from "./button.module.css"

const Button = (props) => {
    return(
        <button className={props.className ? `${style.button} ${props.className}` : `${style.button}`}
            onClick={props.onClick}
            type="submit">
                {props.children}
        </button>
    );
};

export default Button;