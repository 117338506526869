import './App.css';
import { Route, Routes } from 'react-router-dom'
import Main from './structures/MainDiv/main';
import Home from "./pages/home/home";
import Contact from './pages/contact/contact';
import Gates from './pages/compuertas/gates';
import Telemetry from './pages/telemetry/telemetry';
import WaterQuality from './pages/WaterQuality/waterQuality';
import Platform from './pages/platform/platform';
import TermsAndConditions from './pages/terms_and_conditions/tndconditions';
import Gallery from './pages/gallery/gallery';
import FAQs from "./pages/faqs/faqs";
import Trevolt from './pages/trevolt/trevolt';
import Footer from './components/footer/footer';
import PageNotFound from './pages/erros/pageNotFound';
import React from 'react';
//import Dev2 from './components/dev/dev2';

function App() {
  return (
    <React.Fragment>
      <div className="App">
          <Routes>
          {/* PUBLIC ROUTES */}
          <Route path="/" element={ <Home /> }/>
          <Route path="/compuertas" element={ <Gates />}></Route>
          <Route path="/telemetrias" element={<Telemetry />}></Route>
          <Route path="/calidad_de_aguas" element={<WaterQuality />}></Route>
          <Route path="/plataforma" element={<Platform />}></Route>
          <Route path="/solar" element={<Trevolt />}></Route>
          <Route path="/faqs" element={<FAQs />}></Route>
          <Route path="/contacto" element={ <Contact />}></Route>
          <Route path="/galeria" element={ <Gallery />}></Route>
          <Route path="/terminos_y_condiciones" element={ <TermsAndConditions />}></Route>
          <Route path="/dev"></Route>

          {/* OTHERS */}
          <Route path="*" element={ <PageNotFound /> } />

        </Routes>
        <footer>
          <Main className="footerMain">
            <Footer />
          </Main>
        </footer>
      </div>
    </React.Fragment>
  );
}

export default App;
