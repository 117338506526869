import React from "react";
import { useState, useEffect } from "react";
import FsDiv from "../../structures/FullScreenDiv/fsdiv";
import Main from "../../structures/MainDiv/main";
import SDWT from "../../structures/splitDivWithTitle/sdwt";
import NavBar from "../../components/navbar/navbar";
import layout from "../../css/pageLayout.module.css"
import style from "./wq.module.css"
import arrowsDwn from "../../static/arrowsDown.png"
import icon1 from "../../static/calidad_de_agua/icon_1.svg"
import icon2 from "../../static/calidad_de_agua/icon_2.png"
import ph from "../../static/calidad_de_agua/ph.svg"
import oxidacion from "../../static/calidad_de_agua/oxidacion.svg"
import redox from "../../static/calidad_de_agua/redox.svg"
import conductividad from "../../static/calidad_de_agua/conducion_electrica.svg"


const WaterQuality = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [breakline, setBreakLine] = useState(true)

    function getSize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", getSize);
        
        if ((width <= 1078) && (width > 800)) {
            setBreakLine(false);
        }

        if (width <= 800) {
            setBreakLine(true);
        }

        if (width <= 539) {
            setBreakLine(false);
        }
    }, [width])

    return (
        <React.Fragment>
            <header>
                <FsDiv>
                    <NavBar />
                    <Main>
                        <SDWT className={layout.intro}>
                            <div className={`${layout.splitDiv} ${layout.alignLeft} ${style.introTitle}`}>
                                <h1>CALIDAD DE <span className={style.logoSpan}>AGUAS</span></h1>
                                <h3>ANÁLISIS CONSTANTE DE PARÁMETROS INDISPENSABLES.</h3>
                            </div>
                            <div className={layout.splitDiv}>
                                <img src={icon1} alt="ICON1"></img>
                            </div>
                        </SDWT>
                        <div className={layout.navigation}>
                            <img src={arrowsDwn} alt="Go Down"></img>
                        </div>
                    </Main>
                </FsDiv>
            </header>
            <main>
                <Main className={`${layout.dark} ${style.contentDiv}`} hideBkg={true}>
                    <SDWT className={`${style.Content}`}>
                        <div className={`${layout.splitDivCentered} ${style.imgDiv}`}>
                            <img src={icon2} alt="Icon"></img>
                            <div className={style.subTitle}>
                                <div className={style.subTitleContent}>
                                    <p>EL AGUA ES TAN VITAL {breakline ? <br/> : ""}COMO <span>SU CALIDAD</span></p>
                                </div>
                            </div>
                        </div>
                        <div className={layout.splitDivCentered}>
                            <div className={style.listItems}>
                                <div className={style.listItem}>
                                    <div className={style.listIcon}>
                                        <img src={ph} alt="PH" />
                                    </div>
                                    <div className={style.listText}>
                                        <h3>PH</h3>
                                        <p>El PH nos resulta útil para medir la pureza del agua, ya que diluyentes o derrames de alguna solución modificarán el valor de este indicador.</p>
                                    </div>
                                </div>
                                <div className={style.listItem}>    
                                    <div className={style.listIcon}>
                                        <img src={oxidacion} alt="Oxidación" />
                                    </div>
                                    <div className={style.listText}>
                                        <h3>OXÍGENO DISUELTO</h3>
                                        <p>El oxígeno en el agua nos permite conocer la calidad del agua, como también, si es es dañina para la infraestructura donde se desenvolverá.</p>
                                    </div>
                                </div>
                                <div className={style.listItem}>
                                    <div className={style.listIcon}>
                                        <img src={redox} alt="Índice Redox" />
                                    </div>
                                    <div className={style.listText}>
                                        <h3>ORP</h3>
                                        <p>El índice de redox nos permite conocer la potabilidad del agua, es un estándar comúnmente utilizado con este fin.</p>
                                    </div>
                                </div>
                                <div className={style.listItem}>
                                    <div className={style.listIcon}>
                                        <img src={conductividad} alt="Conductividad" />
                                    </div>
                                    <div className={style.listText}>
                                        <h3>CONDUCCIÓN ELÉCTRICA</h3>
                                        <p>La conducción eléctrica del agua nos permite medir la concentración de sólidos disueltos en el agua.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SDWT>
                </Main>
            </main>
        </React.Fragment>
    );
}
 
export default WaterQuality;