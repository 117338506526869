import FsDiv from "../../structures/FullScreenDiv/fsdiv";
import Main from "../../structures/MainDiv/main";
import SDWT from "../../structures/splitDivWithTitle/sdwt";
import Card from "../../structures/Card/card";
import NavBar from "../../components/navbar/navbar";
import cstyle from "./telemetry.module.css";
import icon1 from "../../static/telemetry/icon_1.svg"
import icon2 from "../../static/telemetry/icon_2.svg"
import radar from "../../static/telemetry/radar.svg"
import ultrasound from "../../static/telemetry/ultrasonido.svg"
import presion from "../../static/telemetry/presion.svg"
import question from "../../static/compuertas/question-mark.svg";
import arrowsDwn from "../../static/arrowsDown.png"
import arrowLink from "../../static/link_arrow.png";
import style from"./telemetry.module.css";
import layout from "../../css/pageLayout.module.css"
import React from "react";
import { NavLink as Link } from "react-router-dom";

const Telemetry = () => {
    return (
        <React.Fragment>
            <FsDiv>
                <header>
                    <NavBar />
                    <Main>
                        <SDWT className={layout.intro}>
                            <div className={`${layout.splitDiv} ${layout.alignLeft} ${cstyle.introTitle}`}>
                                <h1><span className={cstyle.logoSpan}>TELEMETRÍA</span></h1>
                                <h3>TODA LA INFORMACIÓN EN TU DISPOSITIVO</h3>
                            </div>
                            <div className={layout.splitDiv}>
                                <img src={icon1} alt="ICON1"></img>
                            </div>
                        </SDWT>
                        <div className={layout.navigation}>
                            <img src={arrowsDwn} alt="Go Down"></img>
                        </div>
                    </Main>
                    
                </header>
            </FsDiv>
            <main>
                <section>
                    <Main className={layout.dark} hideBkg={true}>
                        <SDWT title="¿QUÉ ES LA" last="TELEMETRÍA?">
                            <div className={layout.splitDivCentered}>
                                <img src={icon2} alt="Energización"  className={layout.icon}></img>
                            </div>

                            <div className={layout.splitDivCentered}>
                                <p>La telemetría es un concepto poco conocido, pero que cotidianamente es muy utilizado por todos, ya que no es más que la medición de algún parámetro que será desplegado en un dispositivo remoto. 
        En este caso, la telemetría nos permitirá medir la altura de agua, ya sea en un canal o tranque para estimar el caudal o volumen. Para esto, existen una variedad de sensores ya sean de presión, radar, ultrasonido, entre otros.
                                </p>
                                <p>
                                Los datos enviados a la plataforma se almacenan en la nube, por lo que nuestros clientes cuentan con un registro histórico de caudal o volumen, lo cual incrementa el potencial para una toma de decisiones eficiente. 
        Por otra parte, nosotros comprendemos la importancia de la visualización intuitiva de los datos, es por esto que nuestra plataforma cuenta con un gráfico dinámico, una tabla y la posibilidad de descargar los datos crudos para análisis más específicos si es requerido.
                                </p>
                                <div className={layout.link}>
                                    <Link to="/plataforma">
                                        Ver más sobre la plataforma Metric Pro
                                        <img src={arrowLink} className={layout.go} alt="Go"></img>
                                    </Link>
                                </div>
                            </div>
                        </SDWT>
                    </Main>
                </section>
                <section>
                    <Main>
                        <SDWT title="TIPOS DE" last="SENSORES">
                            <div className={`${layout.verticalDiv} ${cstyle.Text}`}>
                                <p>La telemetría en canales nos permite conocer la altura y caudal/volumen en algún punto específico, con la finalidad de mejorar la toma de desiciones.</p>
                                <p>Para levantar esta información se requiere de un sensor y una estructura uniforme que nos permita realizar modelaciones que permiten estimar el caudal o volumen en función de la altura observada.</p>
                                <div className={`${cstyle.Cards}`}>
                                    <Card Title="SENSOR DE RADAR" className={cstyle.card} src={radar} contentClassName={style.cardContent}>
                                        Los sensores de radar funcionan a través de la emisión de ondas electromagnéticas, las cuales se verán afectas por los materiales en su trayectoria y nos permitirá saber la distancia hasta el nivel de agua. 
                                    </Card>
                                    <Card Title="SENSOR DE ULTRASONIDO" className={cstyle.card} src={ultrasound} contentClassName={style.cardContent}>
                                        Los sensores ultrasónicos envían una onda de sonido, la cuál rebotará al alcanzar el agua. Al conocer la velocidad del sonido y el tiempo de viaje, podremos conocer la altura.  
                                    </Card>
                                    <Card Title="SENSOR DE PRESIÓN" className={cstyle.card} src={presion} contentClassName={style.cardContent}>
                                        Los sensores de presión o sensor de nivel piezometrico se instala bajo el agua. A través de una membrana, podemos conocer la presión que ejerce la columna de agua sobre el sensor, y por tanto la altura por sobre este.
                                    </Card>
                                </div>
                            </div>
                        </SDWT>
                        <div className={style.listItem}>    
                            <div className={style.listIcon}>
                                <div className={style.iconBox}>
                                    <img src={question} alt="question mark" />
                                </div>
                                <div className={style.titleBox}>
                                    <h3>¿CÓMO SE {"\n"}<span>DIFERENCIAN?</span></h3>
                                </div>
                            </div>
                            <div className={style.listText}>
                                <p>El sensor de radar es más robusto que el de ultrasonido, ya que este no se verá sesgado tras la presencia de partículas o solidos presentes en el agua.</p>
                                <p>Por otra parte, los sensores piezometricos también conocidos como de "presión" requieren de más mantenciones producto que a diferencia de los precedentes tiene contacto directo con el agua.</p>
                            </div>
                        </div>
                    </Main>
                </section>
            </main>
        </React.Fragment>
     );
}
 
export default Telemetry;