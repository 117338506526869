import Logo from "../../static/logo.png";
import Mobile from "../../static/footer/mobile.png";
import Message from "../../static/footer/message.png";
import Location from "../../static/footer/location.png";
import Web from "../../static/footer/web.png";
import style from "./footer.module.css";
import { NavLink as Link } from "react-router-dom";

const Footer = () => {
    return ( 
        <div className={style.footer}>
            <div className={style.columns}>
                <div className={`${style.footerColumn} ${style.copyright}`}>
                    <img src={Logo} alt="logo"></img>
                    <p>Copyright © 2022 Metric Pro.<br/>
                        Derechos Reservados
                    </p>
                </div>
                <div className={`${style.footerColumn} ${style.navigation}`}>
                    <h3>CONTACTA CON NOSOTROS</h3>
                    <p>Háganos una pregunta o solicite una llamada y nos pondremos en contacto con usted lo antes posible.</p>
                    <Link to="/contacto"><button type="button">DÉJANOS UN MENSAJE</button></Link>
                </div>
                <div className={`${style.footerColumn} ${style.navigation}`} style={{width: "auto"}}>
                    <h3>GESTIÓN HÍDRICA</h3>
                    <Link to="/compuertas">COMPUERTAS</Link>
                    <Link to="/telemetrias">TELEMETRÍA</Link>
                    <Link to="/calidad_de_aguas">CALIDAD DE AGUAS</Link>
                    <Link to="/plataforma">PLATAFORMA</Link>
                </div>
                <div className={`${style.footerColumn} ${style.navigation}`} style={{width: "auto"}}>
                    <h3>MÁS</h3>
                    <Link to="/solar">PANELES SOLARES</Link>
                    <Link to="/faqs">PREGUNTAS FRECUENTES</Link>
                    <Link to="/galeria">GALERÍA</Link>
                    <Link to="/terminos_y_condiciones">TÉRMINOS Y CONDICIONES</Link>
                </div>
            </div>
            <div className={`${style.contacts} ${style.navigation}`}>

                <div className={style.contactDetails}>
                    <img src={Mobile} alt="mobile icon"></img>
                    <h3>+569 8805 4266</h3>
                </div>
                <span>|</span>
                <div className={style.contactDetails}>
                    <img src={Message} alt="message icon"></img>
                    <h3>ventas@metricpro.cl</h3>
                </div>
                <span>|</span>
                <div className={style.contactDetails}>
                    <img src={Location} alt="location icon"></img>
                    <h3>Av. Concón Reñaca 41{"\n"}
                        oficina 4, Concón
                    </h3>
                </div>
                <span>|</span>
                <div className={`${style.contactDetails} ${style.contactsLink}`}>
                    <img src={Web} alt="web icon"></img>
                    <h3><a href="https://web05.metricpro.cl/app/login">Acceso  Clientes</a></h3>
                </div>
            </div>
        </div>
    );
}
 
export default Footer;