import React from "react";
import { useEffect, useState } from "react";
import FsDiv from "../../structures/FullScreenDiv/fsdiv";
import Main from "../../structures/MainDiv/main";
import SDWT from "../../structures/splitDivWithTitle/sdwt";
import NavBar from "../../components/navbar/navbar";
import layout from "../../css/pageLayout.module.css"
import Button from "../../structures/button/button";
import style from "./home.module.css";
import bg from "../../static/home/landing_optimized.png";
import bg2 from "../../static/home/bg2.png";
import bg3 from "../../static/home/bg3.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import {Parallax, Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css/navigation";
import "./swiperhome.css";
import arrowsWhite from "../../static/arrowsWhite.svg"
import icon1 from "../../static/home/plataformIcon.svg";
import gates from "../../static/home/gates.svg";
import telemetry from "../../static/home/telemetria.svg";
import quality from "../../static/home/calidad.svg";
import icon2 from "../../static/home/aboutus.svg";
import values from "../../static/home/values.svg";
import valuesMobile from "../../static/home/values_2.svg";
import { NavLink as Link } from "react-router-dom";


const Home = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [valuesIcon, setValues] = useState(values);

    function getSize() {
        setWidth(window.innerWidth);
    }
    useEffect( () => {
        window.addEventListener("resize", getSize);
        if (width > 425) {
            setValues(values);
        } else {
            setValues(valuesMobile);
        }
    }, [width]);

    function scrollToServices(e) {
        let pageHeight = window.innerHeight;
        window.scrollBy(0, pageHeight);
    }

    return(
        <React.Fragment>
            <header>
                <NavBar />
                <Main>
                    <div className={`${style.landingContent} ${style.landingFirst}`}>
                        <h1>PRESICIÓN<br/><span>ECO-EFICIENTE</span></h1>
                        <a onClick={scrollToServices}><h3>EXPLORE LAS SOLUCIONES QUE BRINDAMOS <img className={style.linkAnimation} src={arrowsWhite} alt=">>"></img></h3></a>

                    </div>
                    <div className={`${style.landingContent} ${style.landingSecond}`}>
                        <h1>INNOVACIÓN</h1>
                        <h3>Y <span>TECNOLOGÍA</span></h3>
                    </div>
                </Main>
                <FsDiv>
                    <Swiper
                    style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                        "paddingTop": "0",
                        "top": "0",
                        "position": "absolute",
                    }}
                    modules={[Navigation, Pagination, Autoplay, Parallax]}
                    parallax={true}
                    navigation
                    className="swiperHome"
                    slidesPerView={1}
                    autoplay={{
                        delay:2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable:true,
                    }}
                    loop
                    spaceBetween={50}
                    >
                        <SwiperSlide>
                            <img src={bg} alt="Vista Aerea de proyecto de compuertas"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={bg2} alt="Sección lateral de compuertas"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={bg3} alt="Caudal de compuertas"/>
                        </SwiperSlide>
                    </Swiper>
                </FsDiv>
            </header>
            <main>
                <section id="servicios">
                    <Main>
                        <SDWT title="NUESTROS" last="SERVICIOS">
                            <div className={style.serviceWrapper}>
                                <div className={style.bkgOnHover}>
                                <div className={style.serviceGlass}>
                                    <div className={style.serviceColumn}>
                                        <Link to="/compuertas"><img alt="Compuertas" src={gates}/></Link>
                                        <Link to="/compuertas"><h3>COMPUERTAS</h3></Link>
                                    </div>
                                </div>
                                </div>
                                <div className={style.bkgOnHover}>
                                <div className={style.serviceGlass}>
                                    <div className={style.serviceColumn}>
                                        <Link to="/telemetrias"><img alt="Telemetría" src={telemetry}/></Link>
                                        <Link to="/telemetrias"><h3>TELEMETRÍA</h3></Link>
                                    </div>
                                </div>
                                </div>
                                <div className={style.bkgOnHover}>
                                <div className={style.serviceGlass}>
                                    <div className={style.serviceColumn}>
                                        <Link to="/calidad_de_aguas"><img alt="Calidad de Aguas" src={quality}/></Link>
                                        <Link to="/calidad_de_aguas"><h3>CALIDAD DE AGUAS</h3></Link>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </SDWT>
                    </Main>
                </section>
                <section>
                    <Main className={style.platformDiv} hideBkg={true}>
                        <SDWT className={`${layout.intro} ${style.platformDivContainer}`}>
                            <div className={`${layout.splitDiv} ${layout.alignLeft} ${style.innerContent} ${style.responsiveTitle}`}>
                                <h1>PLATAFORMA<br/><span>METRIC PRO</span></h1>
                                <h3>Para nuestros clientes, proporcionamos una plataforma web que perimte monitorear, gestionar y obtener estadísticas de sus equipos.</h3>
                                <div className={style.buttons}>
                                    <Link to="/plataforma"><Button type="button" className={style.platformLink}>MÁS INFO</Button></Link>
                                    <a href="https://web05.metricpro.cl/app/login/"><Button type="button" className={style.accessLink}>ACCESO CLIENTES</Button></a>
                                </div>
                            </div>
                            <div className={`${layout.splitDiv} ${style.platformIcon}`}>
                                <img src={icon1} alt="ICON1" id={style.platIcon}></img>
                            </div>
                        </SDWT>
                    </Main>
                </section>
                <section>
                    <Main>
                        <SDWT title="SOBRE" last="NOSOTROS" className={style.contentDiv}>
                            <div className={layout.splitDivCentered}>
                                <img src={icon2} alt="Energización" id={style.aboutUsIcon}></img>
                            </div>

                            <div className={layout.splitDivCentered}>
                                <p>Metric Pro es una empresa que mediante la vanguardia tecnológica busca generar soluciones integrales para un futuro más sustentable.</p>
                                <p>Nuestra empresa fue gestada en la Región de Valparaíso en 2017 como consecuencia de la creciente urgencia de adoptar nuevas herramientas de gestión hídrica producto de la mermada disponibilidad de agua, tanto en consumo humano como industrial.</p>
                                <p>Nuestra misión es generar soluciones tecnológicas que generen una transformación en la administración de los recursos hídricos, transformándonos en un aliado estratégico en la lucha contra la escasez hídrica presente en el país.</p>
                                <p>Entre nuestras soluciones tecnológicas se encuentran sistemas de telemetría en canales, que permiten monitorear y controlar el flujo y la calidad del agua de forma remota y eficiente, como también compuertas que facilitarán la gestión de este vital recurso.</p>
                            </div>
                        </SDWT>
                    </Main>
                </section>
                <section>
                    <Main>
                        <SDWT title="NUESTROS" last="VALORES" className={style.contentDiv}>
                            <div className={`${style.values} ${layout.bottomSpace}`}>
                                <img src={valuesIcon} alt="Imagen con los valores de la empresa"/>
                            </div>
                        </SDWT>
                    </Main>
                </section>
            </main>
        </React.Fragment>
    );
};

export default Home;