import style from "./navbar.module.css"
import logo from "../../static/logo.png"
import bars from "../../static/bars.png"
import { NavLink as Link } from "react-router-dom";
import { useState, useEffect } from "react";
import whiteArrow from "../../static/navBarArrow.svg"
import blueArrow from "../../static/navBarArrowBlue.svg"

const NavBar = () => {
    const [menu, setMenu] = useState(false);
    const [menuAnimation, setMenuAnimation] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [desktop, setDesktop] = useState(false);
    const [arrow, setArrow] = useState(whiteArrow);
    const [active, setActive] = useState(false);
    const [animate, setAnimate] = useState(false);

    function handleMenu() {
        if (!menuAnimation) {
            setMenuAnimation(true);
        }
        setMenu(!menu);
        if (!menu) {
            setAnimate(false);
            setActive(false);
        }
    }

    function dropDwnHover() {
        if (width > 1050) {
            setArrow(blueArrow);
        }
    }

    function realeseDropDwn() {
        if (width > 1050) {
            if (!active) {
                setArrow(whiteArrow);
            }
        }
    }

    function handleDropDwn() {
        setAnimate(true);
        setActive(!active);
    }

    useEffect(() => {
        if (active) {
            setArrow(blueArrow);
        } else {
            if (!desktop) {
                setArrow(whiteArrow);
            }
        }
    }, [active, desktop])

    function getSize() {
        setWidth(window.innerWidth);
    }
    useEffect( () => {
        window.addEventListener("resize", getSize);
        if (width > 1050) {
            setDesktop(true);
            setMenu(true);
            setAnimate(false);
            setMenuAnimation(true);
        } else {
           setDesktop(false);
           setMenu(false);
           if (desktop) {
            setMenuAnimation(false);
           }
        }
    }, [width, desktop]);

    return (
        <div className={style.navWrapper}>
            <nav className={style.nav}>
                <div className={style.logoContainer}>
                    <Link to="/"><img src={logo} alt="logo"/></Link>
                    <button type="button" className={style.bars} onClick={handleMenu}>
                        <img src={bars} alt="Menu"/>
                    </button>   
                </div>
                <div className={menuAnimation?
                    menu?style.linksWrapper:style.collapseWrapper
                    : style.hide}>
                    <div className={style.linksContainer}>
                        <div className={style.logoRep}></div>
                        <Link to="/" >INICIO</Link>
                        <div className={style.dropDwnContainer} onMouseOver={dropDwnHover} onMouseOut={realeseDropDwn}>
                            <button type="button" onClick={handleDropDwn}>
                                <p id="link">GESTIÓN HÍDRICA
                                    <span className={animate?
                                        active?style.up:style.down: ""}>
                                        <img alt=">" src={arrow}/>
                                    </span>
                                </p>
                            </button>
                            <div className={animate?
                                active?style.dropDwnContent:style.dropDwnCollapse
                                : style.hide}>
                                <div className={style.triangle}></div>
                                <div className={style.dropDwnNav}>
                                    <Link to="/compuertas" >COMPUERTAS</Link>
                                    <Link to="/telemetrias" >TELEMETRÍAS  </Link>
                                    <Link to="/calidad_de_aguas" >CALIDAD DE AGUAS</Link>
                                    <Link to="/plataforma/" >PLATAFORMA</Link>
                                </div>
                            </div>
                        </div>
                        <Link to="/solar" >RIEGO SUSTENTABLE</Link>
                        <Link to="/faqs" >PREGUNTAS FRECUENTES</Link>
                        <a href="https://web05.metricpro.cl/app/login" >ACCESO CLIENTES</a>
                    </div>
                </div>
            </nav>
        </div>
    );
}
 
export default NavBar;