import React from "react";
import { NavLink as Link } from "react-router-dom";
import Main from "../../structures/MainDiv/main";
import FsDiv from "../../structures/FullScreenDiv/fsdiv";
import NavBar from "../../components/navbar/navbar";
import SDWT from "../../structures/splitDivWithTitle/sdwt";
import style from"./gates.module.css";
import icon1 from "../../static/compuertas/icon_1.svg";
import icon2 from "../../static/compuertas/icon2.svg";
import cellphone from "../../static/compuertas/phone.svg";
import touch from "../../static/compuertas/hand.svg";
import question from "../../static/compuertas/question-mark.svg";
import arrowsDwn from "../../static/arrowsDown.png";
import layout from "../../css/pageLayout.module.css";
import linkArrow from "../../static/link_arrow.png";


const Gates = () => {
    return (
        <React.Fragment>
            <header>
                <FsDiv>
                    <NavBar />
                    <Main>
                        <SDWT className={layout.intro}>
                            <div className={`${layout.splitDiv} ${layout.alignLeft} ${style.introTitle}`}>
                                <h1><span className={style.logoSpan}>COMPUERTAS</span></h1>
                                <h3>INFORMACIÓN SOBRE LAS COMPUERTAS Y SUS CONFIGURACIONES</h3>
                            </div>
                            <div className={layout.splitDiv}>
                                <img src={icon1} alt="ICON1"></img>
                            </div>
                        </SDWT>
                        <div className={layout.navigation}>
                            <img src={arrowsDwn} alt="Go Down"></img>
                        </div>
                    </Main>
                </FsDiv>
            </header>
            <main>
                <section>
                    <Main className={layout.dark} hideBkg={true}>
                        <SDWT title="SISTEMA DE ENERGIZACIÓN DE" last="COMPUERTAS">
                            <div className={layout.splitDivCentered}>
                                <img src={icon2} alt="Energización" className={layout.icon}></img>
                            </div>

                            <div className={layout.splitDivCentered}>
                                <p>Nuestra empresa cuenta con dos modelos de compuertas energizadas, ambas se adaptan a los requerimientos técnicos del canal, pudiendo ser accionada por actuadores lineales o motorreductores MEGEU, según sea conveniente.</p>
                                <p>Estos modelos, comparten el sistema de energización como los componentes electrónicos relacionados al movimiento de la compuerta, el cuál es sencillo y seguro de utilizar, ya que nuestros sistemas de izaje se alimentan con 24V. Como consecuencia de lo anterior, se logra una mayor autonomía por su baja demanda eléctrica.</p>
                                <p>La única diferencia entre ambas soluciones es que el sistema de energización automatizado puede ser accionado y monitoreado remotamente desde nuestra plataforma web, disponible en cualquier dispositivo con acceso a internet.</p>
                                <div className={layout.link}>
                                    <Link to="/plataforma">
                                        Ver más sobre la plataforma Metric Pro
                                        <img src={linkArrow} className={layout.go} alt="Go"></img>
                                    </Link>
                                </div>
                            </div>
                        </SDWT>
                    </Main>
                </section>
                <section>
                    <Main>
                        <SDWT title="TIPOS DE" last="COMPUERTAS">
                            <div className={`${layout.verticalDiv} ${style.Text}`}>
                                <div className={style.listItems}>
                                    <div className={style.listItem}>
                                        <div className={style.listIcon}>
                                            <div className={style.iconBox}>
                                                <img src={cellphone} alt="cellhpone" />
                                            </div>
                                            <div className={style.titleBox}>
                                                <h3 className={style.firstItems}>COMPUERTAS AUTOMATIZADAS</h3>
                                            </div>
                                        </div>
                                        <div className={style.listText}>
                                            <p>Las compuertas automatizadas Metric Pro le permitirá a nuestros usuarios accionar la infraestructura de forma remota, es decir, desde computadores, tablets y celulares. De esta forma se podrá tener una mejor capacidad de respuesta a la hora de administrar el agua, ya sea en canales o tranques. A este gran beneficio se adiciona la posibilidad de monitorear la posición y estado de la compuerta.</p>
                                        </div>
                                    </div>
                                    <div className={style.listItem}>    
                                        <div className={style.listIcon}>
                                            <div className={style.iconBox}>
                                                <img src={touch} alt="touch" />
                                            </div>
                                            <div className={style.titleBox}>
                                                <h3 className={style.firstItems}>COMPUERTAS REMOTAS</h3>
                                            </div>
                                        </div>
                                        <div className={style.listText}>
                                            <p>Por otra parte, las compuertas motorizadas si bien utilizan la misma tecnología en la mecánica comparándola con las automatizadas, no se podrá manipular ni monitorear desde nuestra plataforma. Para mover estas compuertas se utiliza una botonera que eliminará el trabajo físico.</p>
                                        </div>
                                    </div>
                                    <div className={style.listItem}>    
                                        <div className={style.listIcon}>
                                            <div className={style.iconBox}>
                                                <img src={question} alt="question mark" />
                                            </div>
                                            <div className={style.titleBox}>
                                                <h3 className={style.lastItem}>¿CÓMO SE {"\n"}<span>DIFERENCIAN?</span></h3>
                                            </div>
                                        </div>
                                        <div className={style.listText}>
                                            <p>El sistema de energización automatizado tiene dos posibles configuraciones, en las que desde una misma caja, se puede controlar una (simple), o dos compuertas (doble).</p>
                                            <p>En el caso de la configuración doble, desde la plataforma el usuario verá ambas compuertas debidamente individualizadas.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SDWT>
                    </Main>
                </section>
            </main>
        </React.Fragment>
    );
}
 
export default Gates;