import NavBar from "../../components/navbar/navbar";
import style from "./platform.module.css";
import layout from "../../css/pageLayout.module.css"
import Main from "../../structures/MainDiv/main";
import FsDiv from "../../structures/FullScreenDiv/fsdiv";
import SDWT from "../../structures/splitDivWithTitle/sdwt";
import decoration1 from "../../static/platform/decoration1.png";
import decoration2 from "../../static/platform/decoration2.png";
import icon1 from "../../static/platform/pc.png";
import arrowsDwn from "../../static/arrowsDown.png";
import phone from "../../static/platform/phone.png";
import telIcon from "../../static/platform/telem_icon.png";
import gateIcon from "../../static/platform/gates_icon.png";
import lineAndPoint from "../../static/platform/phone_pointer.png";
import dot from "../../static/platform/dot.png";
import programacion from "../../static/platform/icon1.svg";
import movimiento from "../../static/platform/icon2.svg";
import registro from "../../static/platform/icon3.svg";
import visualizacion from "../../static/platform/icon4.svg";
import almacenamiento from "../../static/platform/icon5.svg";
import historia from "../../static/platform/icon6.svg";
import React from "react";
import { useState, useEffect } from "react";

const Platform = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [pointer, setPointer] = useState(lineAndPoint);
    const [breakLine, setBreakLine] = useState(false);

    function getSize() {
        setWidth(window.innerWidth);
    }

    useEffect( () => {
        window.addEventListener("resize", getSize);
        if (width > 1522) {
            setBreakLine(true);
        }
        if (width > 710) {
           setPointer(lineAndPoint); 
        } else {
            setPointer(dot);
        }
    }, [width]);

    return (
        <React.Fragment>
            <header>
                <FsDiv className={style.black}>
                    <NavBar />
                    <Main className={style.introBkg}>
                        <div className={style.decor1}>
                            <img src={decoration1} alt="Decoration element"></img>
                        </div>
                        <div className={style.decor2}>
                            <img src={decoration2} alt="Decoration element"></img>
                        </div>
                        <SDWT className={`${layout.intro} ${style.intro}`}>
                            <div className={`${layout.splitDiv} ${layout.alignLeft} ${style.introTitle}`}>
                                <h1>PLATAFORMA {breakLine ? <br/> : ""} <span className={style.bold}>METRIC PRO</span></h1>
                                <h3>Accede a tus recursos desde cualquier dispositivo con conexión a internet.</h3>
                            </div>
                            <div className={layout.splitDiv}>
                                <img src={icon1} alt="ICON1"></img>
                            </div>
                        </SDWT>
                        <div className={layout.navigation}>
                            <img src={arrowsDwn} alt="Go Down" />
                        </div>
                    </Main>
                </FsDiv>
            </header>
            <main>
                <section>
                    <Main className={style.phoneContainer}>
                        <div className={style.title}>
                            <h1>VENTAJAS DE LA <span>PLATAFORMA</span></h1>
                        </div>
                        <div className={style.phoneDivContent}>
                            <div className={style.infoText}>
                                <h1>SIEMPRE AL ALCANCE, DESDE <span>CUALQUIER DISPOSITIVO</span></h1>
                                <p>Tener acceso a una comprensión contextual es vital para tomar decisiones. Pero, tan vital como su posesión es qué tan velozmente se interpreta la información disponible.</p>
                                <p>Por esta razón, en nuestra plataforma de manera transversal  se ha adoptado la opción de mantener un diseño e interfaz intuitivo, pero con potentes funcionalidades.</p>
                            </div>
                            <div className={`${style.infoGraphicsWrapper}`}>
                                <div className={style.infoImg}>
                                    <img src={phone} alt="Plataforma en teléfono"/>
                                </div>
                                <div className={style.imgDescription}>
                                    <div className={style.infoItems} id={style.first}>   
                                        <img src={pointer} id="first" alt="flecha de información"/>
                                        <p>ACTUALIZACIONES CONSTANTES</p>
                                    </div>
                                    <div className={style.infoItems} id={style.second}>
                                        <img src={pointer} id="2" alt="flecha de información"/>
                                        <p>DATOS RESPALDADOS EN LA NUBE</p>
                                    </div>
                                    <div className={style.infoItems} id={style.third}>
                                        <img src={pointer} id="3" alt="flecha de información"/>
                                        <p>NO REQUIERE INSTALACIÓN</p>
                                    </div>
                                    <div className={style.infoItems} id={style.fourth}>
                                        <img src={pointer} id="4" alt="flecha de información"/>
                                        <p>CONEXIÓN Y REPORTES A LA DGA</p>
                                    </div>
                                    <div className={style.infoItems} id={style.fifth}>
                                        <img src={pointer} id="5" alt="flecha de información"/>
                                        <p>SOPORTE ONLINE</p>
                                    </div>
                                    <div className={style.infoItems} id={style.last}>
                                        <img src={pointer} id="6" alt="flecha de información"/>
                                        <p>INERFAZ SIMPLE E INTUITIVA</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Main>
                </section>
                <section>
                    <Main className={style.lists}>
                        <div className={style.gates}>
                            <div className={style.iconDiv}>
                                <img src={gateIcon} alt="Imágen de la plataforma" />
                            </div>
                            <div className={style.listItems}>
                                <div className={style.listItem}>
                                    <div className={style.listIcon}>
                                        <img src={programacion} alt="Programacion" />
                                    </div>
                                    <div className={style.listText}>
                                        <h3>PROGRAMACIÓN DE MOVIMIENTOS</h3>
                                        <p>En el caso de tener patrones de movimientos, se pueden configurar movimientos como si se tratase de "alarmas" en el teléfono.</p>
                                    </div>    
                                </div>
                                <div className={style.listItem}>
                                    <div className={style.listIcon}>
                                        <img src={movimiento} alt="Movimiento" />
                                    </div>
                                    <div className={style.listText}>
                                        <h3>MOVIMIENTO DE COMPUERTAS</h3>
                                        <p>A través de la plataforma, podemos monitorear el estado de la compuerta, como también ejecutar movimientos indicando la altura deseada.</p>
                                    </div>    
                                </div>
                                <div className={style.listItem}>
                                    <div className={style.listIcon}>
                                        <img src={registro} alt="Registro" />
                                    </div>
                                    <div className={style.listText}>
                                        <h3>REGISTRO DE MOVIMIENTOS</h3>
                                        <p>Cada movimiento remoto será registrado en la BBDD, así sabrás quién y cuándo efectuó dicho movimiento.</p>
                                    </div>    
                                </div>
                            </div>
                        </div>
                        <div className={style.telemetries}>
                            <div className={style.listItems}>
                                <div className={style.listItem}>
                                    <div className={style.listIcon}>
                                        <img src={visualizacion} alt="Visualizacion" />
                                    </div>
                                    <div className={style.listText}>
                                        <h3>VISUALIZACIÓN DE DATOS</h3>
                                        <p>Herramientas de visualización dínamicas de datos instantáneos como almacenados.</p>
                                    </div>    
                                </div>
                                <div className={style.listItem}>
                                    <div className={style.listIcon}>
                                        <img src={almacenamiento} alt="Almacenamiento" />
                                    </div>
                                    <div className={style.listText}>
                                        <h3>ALMACENAMIENTO DE DATOS</h3>
                                        <p>Información respaldada y segura en la nube de servidores de alto rendimiento. Porque creemos en la propiedad de la información, tu decides cómo utilizar tus datos.</p>
                                    </div>
                                </div>
                                <div className={style.listItem}>
                                    <div className={style.listIcon}>
                                        <img src={historia} alt="Datos historicos" />
                                    </div>
                                    <div className={style.listText}>
                                        <h3>DATOS HISTÓRICOS</h3>
                                        <p>Porque un imagen no se aprecia sin su contexto, proveemos el histórico de datos almacenados.</p>
                                    </div>
                                </div>
                            
                            </div>
                            <div className={style.iconDiv}>
                                <img src={telIcon} alt="Imágen de la plataforma"/>
                            </div>
                        </div>
                    </Main>
                </section>
            </main>
        </React.Fragment>
    );
}
 
export default Platform;