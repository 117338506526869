import style from "./main.module.css"

const Main = (props) => {
    return(
        <div className={props.className? `${style.main} ${props.className}` : `${style.main}`}>
            <div className={props.className ? 
                props.hideBkg ? style.inner :`${style.inner} ${props.className}` : `${style.inner}`}>
                {props.children}
            </div>
        </div>
    );
};

export default Main;