import React from "react";
import NavBar from "../../components/navbar/navbar";
import Main from "../../structures/MainDiv/main";
import style from "./tndconditions.module.css"
import layout from "../../css/pageLayout.module.css"

const TermsAndConditions = () => {
    return ( 
        <React.Fragment>
            <header>
                <NavBar />
            </header>
            <Main className={style.Page}>
                    <div className={style.Header}>
                        <h1>TÉRIMINOS Y <span>CONDICIONES</span></h1>
                        <h3>SOBRE LOS SERVICIOS Y PRODUCTOS DE METRIC PRO SPA</h3>
                    </div>
                <main className={layout.bottomSpace}>
                    <div className={style.Content}>
                        <p className={style.Articulo}>
                            Artículo 1. Introducción
                        </p>
                        <p className={style.Bajada}>
                        Bienvenido al sitio web de Metric Pro (en adelante, "Sitio"). El uso de este Sitio está sujeto a los siguientes términos y condiciones de uso (en adelante, "Términos y Condiciones"). Al acceder y utilizar este Sitio, usted acepta cumplir con estos Términos y Condiciones. Si no está de acuerdo con estos Términos y Condiciones, no utilice este Sitio.
                        </p>
                        <p className={style.Articulo}>
                            Artículo 2. Propiedad del Sitio
                        </p>
                        <p className={style.Bajada}>
                            El Sitio es propiedad de Metric Pro SPA y está protegido por las leyes de propiedad intelectual de Chile. Todos los derechos reservados.
                        </p>
                        <p className={style.Articulo}>
                            Artículo 3. Responsabilidad y Limitaciones de Responsabilidad
                        </p>
                        <p className={style.Bajada}>
                            Metric Pro hace constantes esfuerzos para garantizar que el contenido del Sitio sea preciso y actualizado. Sin embargo, nuestra empresa no garantiza la exactitud o actualidad de la información contenida en este Sitio. En ningún caso Metirc Pro SPA será responsable por pérdidas o daños, ya sean daños directos o indirectos, o cualquier otro perjuicio derivado del uso del Sitio o de la imposibilidad de usar el Sitio.
                        </p>
                        <p className={style.Articulo}>
                            Artículo 4. Política de Privacidad y Manejo de Datos Personales
                        </p>
                        <p className={style.Bajada}>
                            Nuestra comprende y respeta su privacidad, por lo que nos compromentemos a protegerla. A continuación se detalla nuestra política de privacidad donde se describe cómo recopilamos, utilizamos y protegemos su información:
                        </p>
                        <p className={style.Bajada}>a) Información recopilada y su uso:</p>
                        <ul>
                            <li>Dirección IP: nuestro proveedor de servicios de red, almacena las direcciones IP para proteger nuestro sitio web contra ataques.</li>
                            <li>Información de contacto: si usted se comunica con nosotros a través de nuestra página de contacto, se recopilará su nombre y correo electrónico para poder responder su requerimiento.</li>
                        </ul>
                        <p className={style.Bajada}>b) Almacenamiento:</p>
                        <ul>
                            <li>Dirección IP: La dirección IP es almacenada por nuestro proveedor de servicios de red durante un período limitado.</li>
                            <li>No almacenamos activamente la información de contacto proporcionada a través de la página de contacto. Sin embargo, podemos guardar copias de las comunicaciones para fines legales o de cumplimiento.</li>
                        </ul>
                        <p className={style.Bajada}>c) Seguridad de la información:</p>
                        <p className={style.Bajada}>Metric Pro toma medidas de ciberseguridad para proteger su información personal contra accesos no autorizados, alteraciones, divulgaciones o destrucciones. Mas, no podemos garantizar la seguridad absoluta de su información personal.</p>
                        <p className={style.Bajada}>d) Cambios en la política de privacidad:</p>
                        <p className={style.Bajada}>Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento.</p>
                        <p className={style.Articulo}>
                            Artículo 5. Uso Aceptable
                        </p>
                        <p className={style.Bajada}>
                            El uso de este Sitio está sujeto a las siguientes restricciones:
                        </p>
                        <ul>
                            <li>No puede utilizar este Sitio para acosar, amenazar o dañar a personas, incluyendo al personal de la empresa.</li>
                            <li>No puede utilizar este Sitio para enviar spam (correo no deseado).</li>
                            <li>No puede utilizar este Sitio para violar los derechos de autor u otros derechos de propiedad intelectual.</li>
                        </ul>
                        <p className={style.Articulo}>
                            Artículo 6. Quejas y Conflictos
                        </p>
                        <p className={style.Bajada}>
                            Ante algúna eventual disconformidad con el Sitio, por favor contáctenos a contacto@metricpro.cl. Intentaremos solventar cualquier problema a la brevedad.
                        </p>
                        <p className={style.Articulo}>
                            Artículo 7. Modificaciones y Terminaciones
                        </p>
                        <p className={style.Bajada}>
                            Metric Pro se reserva el derecho de modificar o terminar estos Términos y Condiciones en cualquier momento sin previo aviso.
                        </p>
                        <p className={style.Articulo}>
                            Artículo 8. Servicios de Pago
                        </p>
                        <p className={style.Bajada}>
                            Este sitio web no vende servicios ni productos de forma online, de enfrentarse a algún cobro Metric Pro no se hará responsable por eventuales fraudes.
                        </p>
                    </div>
                </main>
            </Main>
        </React.Fragment>
    );
}
 
export default TermsAndConditions;