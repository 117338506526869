import style from "./gallery.module.css";
import Main from "../../structures/MainDiv/main";
import NavBar from "../../components/navbar/navbar";
import React from "react";
import FsDiv from "../../structures/FullScreenDiv/fsdiv";
import SDWT from "../../structures/splitDivWithTitle/sdwt";
import picture_1 from "../../static/gallery/1.jpg";
import picture_2 from "../../static/gallery/2.jpeg";
import picture_3 from "../../static/gallery/3.jpg";
import picture_4 from "../../static/gallery/4.jpeg";
import picture_5 from "../../static/gallery/5.jpeg";
import picture_6 from "../../static/gallery/6.jpeg";
import picture_7 from "../../static/gallery/7.jpg";
import picture_8 from "../../static/gallery/8.jpeg";
import rotateIcon from "../../static/gallery/rotate.svg";
import { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./swiper.css";
import "swiper/css/lazy";
import { EffectCoverflow, Pagination, Autoplay} from "swiper";

const Gallery = () => {

    // Rotate Logic
    const [width, setWidth] = useState(window.innerWidth);
    const[height, setHeight] = useState(window.innerHeight);
    const [rotate, setRotate] = useState(false);

    function getSize() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect( () => {
        window.addEventListener("resize", getSize);
        if (width/height < 1.25) {
            setRotate(true);
        } else {
           setRotate(false)
        }
    }, [width, height]);

    return (
        <React.Fragment>
            <header>
                <NavBar />
            </header>
            <main>
                <Main>
                    <FsDiv>
                        <div className={rotate? style.hide :style.swiperContainer}>
                            <Swiper
                                effect={"coverlflow"}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={"auto"}
                                spaceBetween={25}
                                lazy={true}
                                coverflowEffect={{
                                    rotate: 50,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 1,
                                    slideShadows: true,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[EffectCoverflow, Pagination, Autoplay]}
                                className="carousel"
                                loop
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                >
                                <SwiperSlide className={style.swiperSlide}>
                                <img src={picture_1} className={style.swiperImg} alt="Compuerta botando agua"/>
                                </SwiperSlide>
                                <SwiperSlide className={style.swiperSlide}>
                                    <img src={picture_2} className={style.swiperImg} alt="Telemetría"/>
                                </SwiperSlide>
                                <SwiperSlide className={style.swiperSlide}>
                                    <img src={picture_3}  className={style.swiperImg} alt="Imágen aérea de compuertas"/>
                                </SwiperSlide>
                                <SwiperSlide className={style.swiperSlide}>
                                    <img src={picture_4}  className={style.swiperImg} alt="Imágen panorámica de compuertas"/>
                                </SwiperSlide>
                                <SwiperSlide className={style.swiperSlide}>
                                    <img src={picture_5}  className={style.swiperImg} alt="Telemetría en un canal"/>
                                </SwiperSlide>
                                <SwiperSlide className={style.swiperSlide}>
                                    <img src={picture_6}  className={style.swiperImg} alt="Set de compuertas"/>
                                </SwiperSlide>
                                <SwiperSlide className={style.swiperSlide}>
                                    <img src={picture_7}  className={style.swiperImg} alt="Canal en construcción"/>
                                </SwiperSlide>
                                <SwiperSlide className={style.swiperSlide}>
                                    <img src={picture_8}  className={style.swiperImg} alt="Infraestructura de compuertas en construcción"/>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                        <div className={rotate? style.messsage :style.hide}>
                            <SDWT className={style.contentDiv}>
                                <div className={style.text}>
                                    <h1>POR FAVOR<br/><span>ROTE EL DISPOSITIVO</span></h1>
                                    <h3>Para ver la galería deberá girar su dispositivo, o incrementar el ancho de la ventana de su navegador.</h3>
                                </div>
                                <div className={style.icon}>
                                    <img src={rotateIcon} alt="Ilustración gráfica de teléfono girando"></img>
                                </div>
                            </SDWT>
                        </div>
                    </FsDiv>
                </Main>
            </main>
        </React.Fragment>
    );
}
 
export default Gallery;