import React from "react";
import NavBar from "../../components/navbar/navbar";
import SDWT from "../../structures/splitDivWithTitle/sdwt";
import style from "./trevolt.module.css";
import logo from "./logo.svg";

const Trevolt = () => {
    return (
        <React.Fragment>
            <header>
                <NavBar />
            </header>
            <main>
                <section className={style.pageContent}>
                        <SDWT className={style.page} mainClassName={style.mainDiv}>
                            <div className={style.splitDiv}>
                                <h3>PROYECTOS <span className={style.logoSpan}>FOTOVOLTAICOS</span></h3>
                                <p>
                                    Trevolt es una empresa que nace con el propósito contribuir a la transición energética de Chile, migrando desde la actual matriz en base a combustibles fósiles a energías limpias.
                                </p>

                                <p>
                                    Es por esto que, nuestra empresa gestiona proyectos ya sean de pequeña escala, hasta plantas de producción. Acompañando a nuestro cliente en todos los procesos que requiera el proyecto, desde el diseño, estudio de prefactibilidad y ejecución.
                                </p>
                                <p>
                                    Para más información visita nuestra web en: <a href="https://www.trevolt.cl">www.trevolt.cl</a>
                                </p>
                            </div>
                            <div className={style.splitDiv}>
                                <img src={logo} alt="Logotipo de Trevolt SpA"/>
                            </div>
                        </SDWT>
                </section>
            </main>
        </React.Fragment>
    );
};

export default Trevolt;