import NavBar from "../../components/navbar/navbar";
import FsDiv from "../../structures/FullScreenDiv/fsdiv";
import Main from "../../structures/MainDiv/main";
import SDWT from "../../structures/splitDivWithTitle/sdwt";
import Icon from "../../static/pagenotfound/icon.svg"
import style from "./pageNotFound.module.css"

const PageNotFound = () => {
    return (
        <FsDiv>
            <header>
                <NavBar hideContent={true}/>
            </header>
            <section>
                <Main className={style.page}>
                    <main className={style.mainDiv}>
                        <SDWT className={style.content}>
                            <div className={style.left}>
                                <h1>ERROR <span>404</span></h1>
                                <h3>ESTA PÁGINA NO EXISTE</h3>
                            </div>
                            <div className={style.right}>
                                <img src={Icon} alt="Ilustración gráfica de página no encontrada"></img>
                            </div>
                        </SDWT>
                    </main>
                </Main>
            </section>
        </FsDiv>
    );
}

export default PageNotFound;