import style from "./sdwt.module.css"

const SDWT = (props) => {
    return (
        <div className={props.mainClassName ? `${style.mainDiv} ${props.mainClassName}` : `${style.mainDiv}`}>
            <div className={props.title ? style.title : style.hidden}>
                <h2 className={props.titleClass ? `${style.title} ${props.titleClass}` : style.title}>
                    {props.title}<span>{props.last? ` ${props.last}`:""}</span>
                </h2>
            </div>
            <div className={props.className?`${style.content} ${props.className}`: style.content}>
                {props.children}
            </div>
        </div>
    );
}
 
export default SDWT;